<template>
  <div class="container">
    <div class="user flex-center">
      <img :src="userInfo.userImg" />
      <span>{{ userInfo.userName }}</span>
    </div>
    <div class="con">
      <div class="con-auth flex-center">
        <div class="flex-column">
          <img class="con-auth_icon" :src="require('../assets/trill_icon_2.png')" alt="">
          <span class="con-auth_title">抖音</span>
        </div>
        <img class="con-auth_center" :src="require('../assets/trill_icon_3.png')" alt="">
        <div class="flex-column">
          <img class="con-auth_icon" :src="require('../assets/trill_icon_1.png')" alt="">
          <span class="con-auth_title">书中自有文化</span>
        </div>
      </div>
      <van-button class="con-btn" type="primary" block @click="onClickAuth">立即授权</van-button>
      <div class="tip flex-column_left">
        <span>温馨提示：</span>
        <span>若授权失败，请将抖音APP更新至10.8.0以上版本后重试</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { getAuthInfo, getTrillStatus, getScopesListApi } from '../api/trillAuth'
export default {
  name: "TrillAuth",
  data() {
    return {
      userInfo: {
        userImg: '',
        userName: ''
      },
      userId: '',
      sdk: null,
      ready: false,
      configStatus: '',
      resultMsg: '',
      client_key: '',
    }
  },
  created() {
    const ua = navigator.userAgent;
    // eslint-disable-next-line no-unused-vars
    console.log('环境111-------', ua)
    if (!/aweme/.test(ua)) {
      this.$router.replace('/trill_tip')
      return
    }
    document.title = '抖音授权'
    if (this.$route.query.state) {
      this.userId = this.$route.query.state
    }

    this.setSdk()
  },
  methods: {
    onClickAuth() {
      console.log('ready进入----');
      if (this.ready) {
        console.log('进入----');
        getScopesListApi().then(res => {
          console.log('res--------', res)
          this.sdk.showOpenAuth({
            params: {
              client_key: this.client_key,
              state: '',
              scopes: res.data.scopeObj,
              response_type: 'code'
            },
            success: (res) => {
              this.$bl.sum('event-trill-auth-success')
              let { ticket, response } = res
              let permissionList = response.grant_permissions.split(',')
              if (permissionList.length > 1) {
                this.gotoResult(ticket)
              }
            },
            error: (res) => {
              this.$bl.sum('event-trill-auth-fail')
              console.log(res)
              // this.setResultMsg = `Auth Error: ${JSON.stringify(res)}`
              // 危险通知
              Notify({ type: 'danger', message: '授权失败' });
            }
          })
        }).catch(() => {
          console.log('报错---')
        })
      }
    },

    async gotoResult(ticket) {
      const res = await getTrillStatus({ state: this.userId, code: ticket })
      if (res.code == 0) {
        this.$router.replace('/trill_success')
        this.$bl.sum('event-api-status-success')
      } else if (res.code == 10005) {
        this.$router.replace(`/trill_lock?bindId=${res.data.bindId}&state=${this.userId}`)
        this.$bl.sum('event-api-status-lock')
      } else {
        this.$router.push('/trill_fail')
        this.$bl.sum('event-api-status-fail')
      }
    },

    async config() {
      const { data } = await getAuthInfo({ state: this.userId })
      this.$bl.sum('event-api-auth-info-success')
      this.userInfo = data.authInfo
      this.client_key = data.singMap.client_key
      this.sdk.config({
        params: {
          client_key: data.singMap.client_key,
          signature: data.singMap.signature,
          timestamp: data.singMap.timestamp,
          nonce_str: data.singMap.nonce_str,
          url: data.singMap.url
        }
      })
    },

    setSdk() {
      this.sdk = window.douyin_open
      if (this.sdk) {
        this.configStatus = 'SDK Loaded';
        this.config();
        this.sdk.ready(() => {
          this.$bl.sum('event-trill-ready-success')
          this.ready = true;
          this.configStatus = 'SDK Config Ready';
        });
        this.sdk.error(res => {
          this.$bl.sum('event-trill-ready-fail')
          this.configStatus = `SDK Config Error: ${JSON.stringify(res)}`;
        });
      }
    }
  }
}
</script>

<style scoped>
/* 设计稿宽度为 375
 1 vw = 3.75px */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  background-image: url('../assets/trill_bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column_left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user {
  margin-top: 3.47vw;
}

.user span {
  font-size: 14px;
  color: #666;
  margin-left: 5.3vw;
}

.user img {
  width: 13.3vw;
  height: 13.3vw;
  border-radius: 50%;
  overflow: hidden;
}

.con-auth {
  margin-top: 26.7vw;
  margin-bottom: 21.3vw;
}

.con-auth_icon {
  width: 14.93vw;
  height: 14.93vw;
}

.con-auth_center {
  width: 6.67vw;
  height: 5.33vw;
  margin: 5.6vw 6.67vw 0 9.33vw;
  align-self: flex-start;
}

.con-auth_title {
  color: #232323;
  font-size: 12px;
  margin-top: 2.4vw;
}

.con-btn {
  width: 84vw;
  height: 13.07vw;
  line-height: 13.07vw;
  border-radius: 59.2vw;
  background: linear-gradient(141.26deg, #FF5733 0%, #FF8C42 100%);
  margin: 0 auto;
  border: 0;
  font-size: 16px;
  font-weight: 500;
}

.tip {
  margin-top: 6.4vw;
  font-size: 12px;
  color: #666;
}
</style>
