import service from "../request";



export function getAuthInfo(query) {
    return service({
        url: '/api/sklm/trillBinding/authInfo',
        method: 'get',
        params: query
    })
}
export function getTrillStatus(query) {
    return service({
        url: '/api/sklm/trillBinding/callBackNew',
        method: 'get',
        params: query
    })
}
export function getTrillLockInfo(query) {
    return service({
        url: '/api/sklm/trillBinding/callBackCheck',
        method: 'get',
        params: query
    })
}
export function getScopesListApi(query) {
    return service({
        url: '/api/sklm/trillBinding/authScope',
        method: 'get',
        params: query
    })
}